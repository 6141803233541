import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { DotfileOnboardingApp } from 'frontend-components';

import { bootstrapConfig, fonts, tokens } from './config';
import customEn from './config/locales/en.json';
import customFr from './config/locales/fr.json';
import customDe from './config/locales/de.json';
import customEs from './config/locales/es.json';
import customIt from './config/locales/it.json';
import customNl from './config/locales/nl.json';
import { LogoByLanguage } from './app/logo-by-language';

const root = ReactDOM.createRoot(
  document.getElementById('app-root') as HTMLElement,
);

root.render(
  <StrictMode>
    <DotfileOnboardingApp
      theme={{ tokens, fonts }}
      translations={{
        en: customEn,
        fr: customFr,
        de: customDe,
        es: customEs,
        it: customIt,
        nl: customNl,
      }}
      config={bootstrapConfig}
      logo={LogoByLanguage}
    />
  </StrictMode>,
);
