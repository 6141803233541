import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import Logo from '../assets/logo.svg';
import LogoFR from '../assets/logo-fr.svg';

export const LogoByLanguage = () => {
  useTranslation();
  const [isFrLanguage, setIsFrLanguage] = useState<boolean>(
    i18next.language === 'fr',
  );

  useEffect(() => {
    if (i18next.language === 'fr') {
      setIsFrLanguage(true);
    } else {
      setIsFrLanguage(false);
    }
  }, [i18next.resolvedLanguage]);

  if (isFrLanguage) {
    return <LogoFR />;
  }

  return <Logo />;
};
