import { AppConfig, Environment } from 'shared-domain';

import { environment } from '../environments/environment';
import { companyFields } from './company';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: 'Goldbroker',
  languages: ['fr', 'en', 'de'],
  queryParamsRequired: ['email'],
  supportLink: 'mailto:support@goldbroker.com',
  hasKyb: false,
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
};
