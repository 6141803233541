const brandColors = {
  primary: '#0C151A',
  secondary: '#F0D7A5',
};

const sidebarColors = {
  backgroundColor: '#0C151A',
  color: '#ffffff',
  dotfileLogo: '#ffffff',
};

const linkColors = {
  color: '#718096',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
